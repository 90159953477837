import React, { useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Checkbox,
} from 'antd';
import dayjs from 'dayjs';
import {
  searchPatients,
  updateLinkCallByCaseID,
} from '../../utils/fetchPatients';
import { parseFieldSetup } from '../../utils/stringUtils';

const LinkPatientModal = ({ isOpen, userInfo, setIsOpen, selectedPatient }) => {
  const [loading, setLoading] = useState({
    linking: false,
    searching: false,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCaseIDs, setSelectedCaseIDs] = useState(new Set());
  const [searchData, setSearchData] = useState({
    patientFirstName: '',
    patientLastName: '',
    dateFrom: dayjs().subtract(24, 'hour'),
    dateTo: dayjs(),
  });

  const isAnySelected = selectedCaseIDs.size > 0;

  const handleLinkPatientClicked = async () => {
    if (selectedPatient && selectedPatient.caseID) {
      const selectedResults = searchResults.filter((result) =>
        selectedCaseIDs.has(result.caseID)
      );

      const data = {
        caseIDOriginal: selectedPatient.caseID,
        caseIDSecs: selectedResults.map((result) => result.caseID),
        linkedBy: userInfo?.username || userInfo?.name || 'Unknown',
      };

      setLoading((prev) => ({ ...prev, linking: true }));
      try {
        await updateLinkCallByCaseID(data);
      } catch (error) {
        console.error('Error linking calls:', error);
      } finally {
        setLoading((prev) => ({ ...prev, linking: false }));
        setTimeout(() => {
          setIsOpen(false);
        }, 1500);
      }
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSearch = async () => {
    setLoading((prev) => ({ ...prev, searching: true }));

    try {
      const results = await searchPatients(searchData);
      const filterResults = results.filter(
        (result) =>
          result.caseID !== selectedPatient.caseID &&
          (result.patientFirstName || result.patientLastName)
      );
      const updatedResults = filterResults.map((result) => ({
        ...result,
        selected: selectedCaseIDs.has(result.caseID),
      }));
      setSearchResults(updatedResults);
    } catch (error) {
      console.error('Error searching patients:', error);
    } finally {
      setTimeout(() => {
        setLoading((prev) => ({ ...prev, searching: false }));
      }, 1500);
    }
  };

  const handleSelect = (caseID) => {
    console.log('called');
    setSelectedCaseIDs((prevSelectedCaseIDs) => {
      const updatedSet = new Set(prevSelectedCaseIDs);
      console.log('updatedSet', updatedSet);
      if (updatedSet.has(caseID)) {
        updatedSet.delete(caseID);
      } else {
        updatedSet.add(caseID);
      }
      // Update searchResults based on the new selectedCaseIDs
      setSearchResults((prevResults) =>
        prevResults.map((result) =>
          result.caseID === caseID
            ? { ...result, selected: updatedSet.has(caseID) }
            : result
        )
      );
      return updatedSet;
    });
  };

  const handleValuesChange = (changedValues, allValues) => {
    setSearchData((prev) => ({
      ...prev,
      ...changedValues,
    }));
  };

  const handleDateChange = (date, dateString, type) => {
    console.log('date', type, date.format('YYYY-MM-DD'));
    setSearchData((prev) => ({
      ...prev,
      [type]: date ? date.format('YYYY-MM-DD') : '',
    }));
  };

  return (
    <Modal
      title="Link Call"
      open={isOpen}
      onCancel={handleCancel}
      width={800}
      keyboard={false}
      maskClosable={false}
      style={{
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>Link the selected Patient into one call</span>
          <Button
            type="primary"
            disabled={!isAnySelected}
            onClick={handleLinkPatientClicked}
            loading={loading.linking}
          >
            Link
          </Button>
        </div>
      }
    >
      <div style={{ marginBottom: '16px' }}>
        <h2
          style={{
            borderBottom: '2px solid #ddd',
            paddingBottom: '8px',
            marginBottom: '16px',
          }}
        >
          Search
        </h2>
        <Form
          layout="vertical"
          onValuesChange={handleValuesChange}
          initialValues={searchData}
        >
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item name="patientFirstName" label="Patient Firstname">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="patientLastName" label="Patient Lastname">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="dateFrom" label="Date From">
                <DatePicker
                  value={
                    searchData.dateFrom ? dayjs(searchData.dateFrom) : null
                  }
                  onChange={(date, dateString) =>
                    handleDateChange(date, dateString, 'dateFrom')
                  }
                  maxDate={dayjs(new Date())}
                  mode="date"
                  showNow={false}
                  allowClear={false}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="dateTo" label="Date To">
                <DatePicker
                  value={searchData.dateTo ? dayjs(searchData.dateTo) : null}
                  onChange={(date, dateString) =>
                    handleDateChange(date, dateString, 'dateTo')
                  }
                  maxDate={dayjs(new Date())}
                  mode="date"
                  showNow={false}
                  allowClear={false}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                onClick={handleSearch}
                loading={loading.searching}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ marginBottom: '16px' }}>
        <h2 style={{ borderBottom: '2px solid #ddd', paddingBottom: '8px' }}>
          Result
        </h2>
        <Table
          bordered
          dataSource={searchResults}
          columns={[
            {
              title: 'Patient Name',
              dataIndex: 'patientFullName',
              key: 'patientFullName',
              render: (_, record) => (
                <span>{`${record?.patientFirstName ? ' ' + parseFieldSetup(record.patientFirstName) : ''}${record?.patientLastName ? ' ' + parseFieldSetup(record.patientLastName) : ''}`}</span>
              ),
            },
            {
              title: 'Date Time',
              dataIndex: 'createTime',
              key: 'createTime',
              render: (_, record) => (
                <span>
                  {record?.createTime
                    ? dayjs(record?.createTime)
                        .utc()
                        .format('DD MMM YYYY HH:mm:ss')
                    : ''}
                </span>
              ),
            },
            {
              title: 'Select to link',
              key: 'action',
              render: (_, record) => (
                <Checkbox
                  checked={selectedCaseIDs.has(record.caseID)}
                  onChange={(e) => {
                    handleSelect(record.caseID);
                  }}
                />
              ),
            },
          ]}
          size="small"
          pagination={{
            pageSize: 5,
            size: 'small',
            position: ['bottomCenter'],
            showSizeChanger: false,
          }}
          sticky={{
            offsetHeader: 0,
          }}
        />
      </div>
    </Modal>
  );
};

export default LinkPatientModal;
